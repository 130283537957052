<script setup>
import {Link} from "@inertiajs/vue3";
import Loader from "@/Components/Loader.vue";

defineProps({
    type: {
        type: String,
        default: 'button',
    },
    // optional, renders anchor tag when set
    href: String,
    loading: Boolean,
});

const classes = "inline-flex items-center justify-center px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 tracking-widest shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150"
</script>

<template>
    <Link v-if="href" :href="href" :class="classes">
        <Loader v-if="loading" :loading="loading" class="h-full w-4 mr-2"/>

        <slot />
    </Link>
    <button v-else :type="type" :class="classes">
        <Loader v-if="loading" :loading="loading" class="h-full w-4 mr-2"/>

        <slot />
    </button>
</template>
